import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import YouTube from 'components/youtube'
import { Helmet } from 'react-helmet'
import Button from 'components/button/button'
import { graphql, Link } from 'gatsby'
import Layout from 'components/layout'
import Content from 'components/new/content'
import JobsSearch from 'components/jobs-search'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import JobListingAlertsForm from 'components/forms/job-listing-alerts'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Ul from 'components/new/ul'
import Spacer from 'components/new/spacer'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import AdditionalResources from 'components/new/additional-resources'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { useAsync } from 'react-use'
import { SkeletonItem } from 'components/skeleton'
import { fetchCareerAreas } from 'utils/sanity/job-requisitions'
import convertArrayToInlineList from 'utils/convert-array-to-inline-list'

const CareersPage = props => {
  const {
    data: { allSanityLocation: { states: allSanityLocationStates, totalCount: locationCount },heroImage, heroImageMobile, partsImage, serviceImage, salesImage },
  } = props

  const heroImages = withArtDirection(getImage(heroImage), [
    {
      media: '(max-width: 600px)',
      image: getImage(heroImageMobile),
    },
  ])

  allSanityLocationStates.sort((a, b) => b.totalCount - a.totalCount)

  const statesList = allSanityLocationStates.map(state => state.fieldValue)

  const statesListString = convertArrayToInlineList(statesList)

  return (
    <Layout>
      <Helmet>
        <title>Careers | Hutson Inc</title>
        <meta
          name='keywords'
          content='jobs, careers, sales, service, parts, accounting, finance, marketing, ams technology, crop consulting, export, human resources, hr, trucking, it, Hutson, positions, hiring'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'VideoObject',
            'name': 'Hutson Inc Company Video',
            'description':
              'Since its founding in 1928, the Hutson name has been coincident with innovative agricultural products and services. With a foundation built upon a strong commitment to customer service, we have sunk our roots deep in the community. From our humble beginnings more than 90 years ago, Hutson has gro...',
            'thumbnailUrl': 'https://i.ytimg.com/vi/XAF4TLZwANc/hqdefault.jpg',
            'uploadDate': '2018-09-26T20:37:29.000Z',
            'duration': 'PT2M29S',
            'embedUrl': 'https://youtube.googleapis.com/v/XAF4TLZwANc',
          })}
        </script>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Careers',
                'item': 'https://www.hutsoninc.com/careers/',
              },
            ],
          })}
        </script>
      </Helmet>

      <Hero image={heroImages} title='Careers' overlayOpacity={0} />

      <Content kind='full'>
        <TopGrid>
          <SearchCol>
            <JobsSearch />
          </SearchCol>
          <MainCol>
            <H2>Why Hutson?</H2>
            <P>
              Hutson has seen plenty of changes since its founding back in 1928, but one thing still
              remains the same: our commitment to our customers and employees. From our very first
              location in Mayfield, KY to our{' '}
              <Link to='/locations/'>
                {locationCount} locations in {statesListString}
              </Link>
              , we have focused on meeting the needs of our customers and co-workers through
              integrity and innovation.
            </P>
            <P>
              Ultimately companies are really about their people; committed, talented, passionate
              people, who want to be challenged, who want to do something meaningful, realize their
              full potential, make a positive impact in their communities, and have fun doing it.
              That’s exactly what Hutson Inc is about. Our people are passionate individuals who get
              involved and make a difference in the lives of our customers and our communities every
              day. We stand for putting our customers first, treating people with respect, and
              thinking creatively to solve problems and we are excited to welcome you to our team.
            </P>
            <Spacer size='m' variable />
            <ButtonContainer>
              <Button as={Link} to='/about/' ghost color='green'>
                Learn more about Hutson
              </Button>
            </ButtonContainer>
          </MainCol>
        </TopGrid>
      </Content>
      <LightSection>
        <Content kind='full'>
          <H2>Career paths</H2>
          <P>
            We are constantly searching for qualified and committed individuals to join our team.
            Our people are passionate about getting involved and making a difference in the lives or
            our customers and the community every day. With over 700 employees in four states, we
            make quite a difference. See where you fit into this team with our career paths below.
          </P>
          <Spacer size='l' />
          <Grid>
            <Column>
              <GatsbyImage
                image={getImage(partsImage)}
                objectFit='cover'
                style={{ width: '100%' }}
                alt=''
              />
              <ColumnContent>
                <H3>Parts</H3>
                <P>
                  You don't need a background in agriculture or service to get started in our parts
                  department. We have several career opportunities, including parts sales, warehouse
                  and management roles.
                </P>
                <Spacer size='s' />
                <ButtonContainer>
                  <Button
                    as={Link}
                    to='/careers/parts/'
                    ghost
                    color='green'
                    aria-label='Learn more about the parts career path'
                  >
                    Learn more
                  </Button>
                </ButtonContainer>
                <Spacer size='m' />
                <Link to='/careers/parts/jobs/'>Browse Parts positions</Link>
              </ColumnContent>
            </Column>
            <Column>
              <GatsbyImage
                image={getImage(serviceImage)}
                objectFit='cover'
                style={{ width: '100%' }}
                alt=''
              />
              <ColumnContent>
                <H3>Service</H3>
                <P>
                  Be part of the team that keeps our customers up and running! We offer roles
                  specializing in ag equipment, lawn and garden equipment, or both. No experience?
                  Jump start your career with the{' '}
                  <Link to='/careers/tech-program/'>John Deere Tech Program</Link>.
                </P>
                <Spacer size='s' />
                <ButtonContainer>
                  <Button
                    as={Link}
                    to='/careers/service/'
                    ghost
                    color='green'
                    aria-label='Learn more about the service career path'
                  >
                    Learn more
                  </Button>
                </ButtonContainer>
                <Spacer size='m' />
                <Link to='/careers/service/jobs/'>Browse Service positions</Link>
              </ColumnContent>
            </Column>
            <Column>
              <GatsbyImage
                image={getImage(salesImage)}
                objectFit='cover'
                style={{ width: '100%' }}
                alt=''
              />
              <ColumnContent>
                <H3>Sales</H3>
                <P>
                  Relationships are a crutial part of our business. If you enjoy working with
                  people, our sales department is the place for you! Provide guidance to our
                  customers by recommending equipment for their specific needs.
                </P>
                <Spacer size='s' />
                <ButtonContainer>
                  <Button as={Link} to='/careers/sales/jobs' ghost color='green'>
                    Browse Sales positions
                  </Button>
                </ButtonContainer>
              </ColumnContent>
            </Column>
          </Grid>
          <Spacer />
          <H3>Support roles</H3>
          <SupportRoles />
        </Content>
      </LightSection>
      <VideoSection>
        <VideoSectionColumn>
          <VideoContainer>
            <YouTube videoId='XAF4TLZwANc' />
          </VideoContainer>
        </VideoSectionColumn>
        <VideoSectionColumn>
          <Content kind='full'>
            <VideoSectionTitle>
              Hear what employees have to say about working for Hutson
            </VideoSectionTitle>
            <VideoSectionP>Learn what Hutson is all about from some of our very own!</VideoSectionP>
          </Content>
        </VideoSectionColumn>
      </VideoSection>
      <Content kind='full'>
        <H2>Take the next step</H2>
        <P>
          Interested in working for Hutson? We hope you take some time to view our current career
          opportunities and apply! If you have any questions, reach out to our team at{' '}
          <a href='mailto:recruiting@hutsoninc.com'>recruiting@hutsoninc.com</a>.
        </P>
        <Spacer size='m' variable />
        <ButtonContainer>
          <Button as={Link} to='/careers/jobs/' ghost color='green'>
            View all open positions
          </Button>
        </ButtonContainer>
        <Spacer />
        <H2>Equal Employment Opportunity</H2>
        <P>
          In order to provide equal employment and advancement opportunities to all individuals,
          employment decisions at Hutson Inc. will be based on merit, qualifications, and abilities.
          Hutson Inc. provides equal employment opportunities (EEO) to all employees and applicants
          for employment without regard to race, color, religion, gender, sexual orientation,
          pregnancy, gender identity or expression, national origin, age, disability, genetic
          information, marital status, amnesty, retaliation or status as a covered veteran in
          accordance with applicable federal, state and local laws. Hutson Inc. complies with
          applicable state and local laws governing non-discrimination in employment in every
          location in which the company has facilities. Hutson Inc. will make reasonable
          accommodations for qualified individuals with known disabilities unless doing so would
          result in an undue hardship. This policy governs all aspects of employment, including
          selection, job assignment, compensation, discipline, termination, and access to benefits
          and training.
        </P>
      </Content>
      <AdditionalResources
        resources={[
          {
            title: 'About Us',
            links: [
              <Link to='/about/'>About us</Link>,
              <Link to='/locations/'>Our locations</Link>,
              <Link to='/contact/'>Contact us</Link>,
            ],
          },
          {
            title: 'Disclosures',
            links: [
              <OutboundLink href='https://www.anthem.com/machine-readable-file/search/'>
                Transparency in coverage
              </OutboundLink>,
            ],
          },
        ]}
      />
      <JobListingAlertsForm />
    </Layout>
  )
}

const LightSection = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const Grid = styled.div`
  @media (min-width: 900px) {
    ${clearfix}
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
`

const Column = styled.div`
  background-color: #fff;

  @media (max-width: 899px) {
    :not(:last-child) {
      margin-bottom: ${props => props.theme.size.l};
    }
  }

  @media (min-width: 900px) {
    ${column('1/3')}
  }
`

const ColumnContent = styled.div`
  padding: ${props => props.theme.size.m};

  a {
    color: ${props => props.theme.color.g400};
  }
`

const ButtonContainer = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
  }
`

const VideoSection = styled.div`
  background-color: ${props => props.theme.color.n700};

  @media (min-width: 900px) {
    display: flex;
  }
`

const VideoSectionColumn = styled.div`
  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
  }
`

const VideoContainer = styled.div`
  background-color: ${props => props.theme.color.n900};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

const VideoSectionTitle = styled(H2)`
  color: #fff;
`

const VideoSectionP = styled(P)`
  color: #fff;
`

const TopGrid = styled.div`
  @media (min-width: 900px) {
    ${clearfix}
    direction: rtl;
  }
`

const MainCol = styled.div`
  margin-top: 40px;

  @media (min-width: 900px) {
    ${column('2/3', 0)}
    direction: ltr;
    display: inline-block;
    float: none !important;
    margin-top: 0;
    padding: 25px 30px 0 0;
    vertical-align: top;
  }

  @media (min-width: 1200px) {
    ${column('3/4', 0)}
  }
`

const SearchCol = styled.div`
  @media (min-width: 900px) {
    ${column('1/3', 0)}
    direction: ltr;
    display: inline-block;
    float: none !important;
    vertical-align: top;
  }

  @media (min-width: 1200px) {
    ${column('1/4', 0)}
  }
`

const SupportRoles = () => {
  const {
    loading,
    error,
    value: careerAreas,
  } = useAsync(async () => {
    const careerAreas = await fetchCareerAreas()
    return careerAreas.filter(careerArea => careerArea.handle !== 'other')
  }, [])

  if (loading) {
    return (
      <SupportRolesSkeleton>
        <div className='column'>
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
        </div>
        <div className='column'>
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
        </div>
        <div className='column'>
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
        </div>
        <div className='column'>
          <SkeletonItem />
          <SkeletonItem />
        </div>
      </SupportRolesSkeleton>
    )
  }

  return (
    <StyledList>
      {error ? (
        <React.Fragment>
          <li>Accounting</li>
          <li>Agronomy</li>
          <li>Finance</li>
          <li>Human Resources</li>
          <li>Information Technology</li>
          <li>Inventory Management</li>
          <li>Marketing</li>
          <li>Office Coordinator</li>
          <li>Precision Ag</li>
          <li>Safety</li>
          <li>Support Center</li>
          <li>Trucking</li>
        </React.Fragment>
      ) : (
        careerAreas.map(careerArea => (
          <li key={careerArea.handle}>
            <Link to={`/careers/${careerArea.handle}/`}>{careerArea.title}</Link>
          </li>
        ))
      )}
    </StyledList>
  )
}

const StyledList = styled(Ul)`
  a {
    color: ${props => props.theme.color.g400};
  }

  @media (min-width: 480px) {
    columns: 2;
    column-gap: 24px;
  }

  @media (min-width: 900px) {
    columns: 4;
  }
`

const SupportRolesSkeleton = styled.div`
  margin-top: 16px;

  @media (min-width: 480px) {
    ${clearfix}
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .column {
      ${column('1/2', 24)}
    }
  }

  @media (min-width: 900px) {
    .column {
      ${column('1/4', 24)}
    }
  }

  .skeleton {
    display: block;
    height: 21px;
    margin-bottom: 6px;

    :nth-child(1) {
      max-width: 120px;
    }

    :nth-child(2) {
      max-width: 145px;
    }

    :nth-child(3) {
      max-width: 100px;
    }
  }
`

export const pageQuery = graphql`
  {
    allSanityLocation(filter: { status: { ne: "permanently-closed" } }) {
        states: group(field: state) {
          totalCount
          fieldValue
        }
        totalCount
      }
    heroImage: file(relativePath: { eq: "careers/careers.jpg" }) {
      ...FullWidthImage
    }
    heroImageMobile: file(relativePath: { eq: "careers/careers-mobile.jpg" }) {
      ...SharpImage600
    }
    partsImage: file(relativePath: { eq: "careers/parts.jpg" }) {
      ...SharpImage900
    }
    serviceImage: file(relativePath: { eq: "careers/service.jpg" }) {
      ...SharpImage900
    }
    salesImage: file(relativePath: { eq: "careers/sales.jpg" }) {
      ...SharpImage900
    }
  }
`

export default CareersPage
